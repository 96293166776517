import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { b2cPolicies } from './policies'
// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENTID,
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: `${window.location.origin}` // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    secureCookies: false
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        if (process.env.NODE_ENV === 'production') {
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              break
            case LogLevel.Info:
              console.info(message)
              break
            case LogLevel.Verbose:
              console.debug(message)
              break
            case LogLevel.Warning:
              console.warn(message)
              break
            default:
              break
          }
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: []
}

export const tokenRequest = {
  scopes: []
}
