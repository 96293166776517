<template>
  <div id="app">
    <router-view />
    <CustomToaster />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomToaster from './components/common/CustomToaster.vue'
import { HTTP } from './utilities/http-common'
import { gtmScript } from '@/utilities/google-analytics-scripts'
export default {
  name: 'App',
  components: {
    CustomToaster,
  },
  computed: {
    ...mapState({
      idToken: (state) => state.common.idToken,
    }),
  },
  created() {
    this.assignToken()
  },
  mounted() {
    this.googleAnalyticsScripts()
  },
  methods: {
    googleAnalyticsScripts() {
      const GA_KEY = process.env.VUE_APP_GA_KEY
      let GA_HEAD_SCRIPT = `${process.env.VUE_APP_GA_HEAD_SCRIPT}`
      let GA_BODY_SCRIPT = `${process.env.VUE_APP_GA_BODY_SCRIPT}`
      if (GA_KEY && GA_HEAD_SCRIPT && GA_BODY_SCRIPT) {
        GA_HEAD_SCRIPT = GA_HEAD_SCRIPT.replace('#GTMKEY', GA_KEY)
        GA_BODY_SCRIPT = GA_BODY_SCRIPT.replace('#GTMKEY', GA_KEY)
      }
      gtmScript(GA_HEAD_SCRIPT, GA_BODY_SCRIPT)
    },
    async assignToken() {
      HTTP.interceptors.request.use(
        async (request) => {
          await this.$store.dispatch('common/getToken')
          if (this.idToken) {
            request.headers.Authorization = `Bearer ${this.idToken}`
          }
          return request
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      HTTP.interceptors.response.use(
        (response) => {
          return response
        },
        async (error) => {
          if (error.response.status === 401) {
            const url = error.response.request.responseURL
            if (url.includes(process.env.VUE_APP_BASEURL) && !url.includes('usersmanagement')) {
              error.response.statusText = '401-Unauthorized'
              error.response.data = '401-Unauthorized'
              await this.$store.dispatch('common/signOut')
            }
          }
          return Promise.reject(error)
        }
      )
    },
  },
}
</script>
<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
