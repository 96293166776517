const getDefaultState = () => {
  return {
    languages: [],
    selectedLanguage: null,
    defaultLanguage: null,
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {}
// actions
const actions = {
}
// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setLanguages(state, payload) {
    state.languages = payload
  },
  setSelectedLanguage(state, payload) {
    state.selectedLanguage = payload
  },
  setDefaultLanguage(state, payload) {
    state.defaultLanguage = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
