<template>
  <div>
    <section class="py-3 px-5 page-banner">
      <router-link to="/settings" v-if="activeTabName != 'General'" class="go-back-link"><BIconArrowLeftShort />Back to settings</router-link>
      <h3 class="page-header py-3">{{ activeTabName }}</h3>
      <b-row class="justify-content-between align-items-center">
        <b-col cols="12" sm="8">
          <b-nav role="navigation">
            <b-nav-item to="/settings/settings-categories/general" exact exact-active-class="active">General</b-nav-item>
            <b-nav-item to="/settings/settings-categories/email" exact exact-active-class="active">Email</b-nav-item>
            <b-nav-item to="/settings/settings-categories/address" exact exact-active-class="active">Address</b-nav-item>
            <b-nav-item to="/settings/settings-categories/link-accounts" exact exact-active-class="active">Link accounts</b-nav-item>
            <b-nav-item to="/settings/settings-categories/security" exact exact-active-class="active">Security</b-nav-item>
            <b-nav-item
              v-if="isAnnouncementEnabled"
              id="announcement-menu-link"
              to="/settings/settings-categories/announcement-banner"
              exact
              exact-active-class="active"
              >Announcement banner</b-nav-item
            >
          </b-nav>
        </b-col>
        <b-col cols="12" sm="4" class="d-flex justify-content-end">
          <b-form-group id="language-form-group" v-if="displayMultilingualDropdown">
            <div class="floating-select-field">
              <b-form-select
                id="language-options"
                class="form-control"
                text-field="languageName"
                value-field="cultureCode"
                :options="languages"
                v-model="selectedCulture"
                @change="setSelectedLanguage()"
                @input="selectedCulture = $event"
                :class="{
                  'is-value-exist': selectedCulture != null,
                }"
                :disabled="isAddLinkAccount"
              >
              </b-form-select>
              <label for="language-options">Language</label>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </section>
    <section class="py-4 px-5">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconArrowLeftShort } from 'bootstrap-vue'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { useAxios } from '@/composables/useAxios'
import { tenantSpecificURL } from '@/utilities/utilities'
export default {
  name: 'SettingsCategories',
  setup() {
    const { response: languagesResponse, error: languagesError, callApi: languagesApi } = useAxios()
    return {
      languagesResponse,
      languagesError,
      languagesApi,
    }
  },
  data() {
    return {
      activeTabName: '',
      selectedCulture: null,
      multilingualDropdownTabs: ['Email', 'Add link account', 'Edit link account'],
    }
  },
  computed: {
    displayMultilingualDropdown() {
      return this.isMultilingualSupportEnabled && this.multilingualDropdownTabs.some((tab) => tab === this.activeTabName)
    },
    isAddLinkAccount() {
      if (this.$route.name === 'Settings-add-link-account') return true
      return false
    },
    getDefaultCulture() {
      if (this.languages && this.languages.length) {
        const defaultLanguge = this.languages.find((language) => language.isDefault)
        return defaultLanguge ? defaultLanguge.cultureCode : ''
      }
      return ''
    },
    isAnnouncementEnabled() {
      return this.selectedTenant && this.selectedTenant.length ? this.selectedTenant[0].isAnnouncementEnabled : false
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      userRole: (state) => state.common.userRole,
      languages: (state) => state.languages.languages,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  components: {
    BIconArrowLeftShort,
  },
  async created() {
    this.setActivatedTab()
    await this.getLanguages()
    this.setSelectedLanguage()
  },
  methods: {
    setActivatedTab() {
      if (this.$route.name === 'General-settings') {
        this.activeTabName = 'General'
      } else if (this.$route.name === 'Email-settings') {
        this.activeTabName = 'Email'
      } else if (this.$route.name === 'Address-settings') {
        this.activeTabName = 'Address'
      } else if (this.$route.name === 'Settings-link-accounts') {
        this.activeTabName = 'Link accounts'
      } else if (this.$route.name === 'Settings-add-link-account') {
        this.activeTabName = 'Add link account'
        this.selectedCulture = this.getDefaultCulture
      } else if (this.$route.name === 'Settings-edit-link-account') {
        this.activeTabName = 'Edit link account'
      } else if (this.$route.name === 'Settings-add-external-link-account') {
        this.activeTabName = 'Add external link account'
      } else if (this.$route.name === 'Settings-edit-external-link-account') {
        this.activeTabName = 'Edit external link account'
      } else if (this.$route.name === 'Settings-security') {
        this.activeTabName = 'Security'
      } else if (this.$route.name === 'Settings-add-security-method') {
        this.activeTabName = 'Add security method'
      } else if (this.$route.name === 'Settings-edit-security-method') {
        this.activeTabName = 'Edit security method'
      } else if (this.$route.name === 'Settings-announcement-banner') {
        this.activeTabName = 'Announcement banner'
      } else {
        this.activeTabName = ''
      }
    },
    async getLanguages() {
      if (this.selectedTenant.length) {
        await this.languagesApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getLanguages) })
        if (this.languagesResponse) {
          this.$store.commit('languages/setLanguages', this.languagesResponse)
          this.selectedCulture = this.getDefaultCulture
          this.$store.commit('languages/setSelectedLanguage', this.getDefaultCulture)
          this.$store.commit('languages/setDefaultLanguage', this.getDefaultCulture)
        }
        if (this.languagesError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'LANGUAGES_LIST_ERROR',
            type: 'danger',
          })
        }
      }
    },
    setSelectedLanguage() {
      this.$store.commit('languages/setSelectedLanguage', this.selectedCulture)
    },
  },
  watch: {
    $route() {
      this.setActivatedTab()
    },
  },
}
</script>
<style lang="scss" scoped>
.page-banner {
  height: auto;
}
</style>
