import Vue from 'vue'
Vue.directive('numberOnly', {
  bind(el, binding, vnode) {
    el.addEventListener('keypress', (e) => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = e.key
      if (!keysAllowed.includes(keyPressed)) {
        e.preventDefault()
      }
    })
  }
})
Vue.directive('alphaNumericUnderscore', {
  bind(el, binding, vnode) {
    el.addEventListener('keydown', (e) => {
      if (/^[a-zA-Z0-9_]*$/.test(e.key)) {
        return true
      }
      else {
        e.preventDefault()
      }
    })
  }
})
Vue.directive('activeBlur', {
  bind(el) {
    el.addEventListener('click', (e) => {
      e.target.blur()
    })
  }
})
Vue.directive('amount', {
  bind: function (el) {
    el.handler = function () {
      el.value = el.value.match(/^\d+\.?\d{0,2}/)
    }
    el.addEventListener('input', el.handler)
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler)
  }
})
