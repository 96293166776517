import { HTTP } from '../../utilities/http-common'
import { ServiceUrls } from '../../utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
const getDefaultState = () => {
  return {
    widgets: [],
    calendarTypeOptions: [],
    applicableWidgets: [],
    signUpPageDisplayedWidgetCount: null,
    bangTheTableIsValid: false,
    perfectMindConfigIsValid: false,
    chartSampleData: {
      series: [],
      labels: [],
    },
    tableSampleData: {
      head: [],
      body: [],
    },
    contentWidgetEmailTemplate: null,
    widgetDescriptions: [],
    addWidgetFormValidStatus: false,
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {}
// actions
const actions = {
  defaultChartSampleData({ commit }) {
    const data = {
      series: [
        { label: new Date().getFullYear() - 1, data: [] },
        { label: new Date().getFullYear(), data: [] },
        { label: '', data: [] },
        { label: '', data: [] },
        { label: '', data: [] },
        { label: '', data: [] },
      ],
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', '', '', '', '', '', ''],
    }
    data.labels.forEach((label) => {
      if (label) {
        data.series.forEach((s) => {
          s.data.push(s.label ? Math.floor(Math.random() * 100) : '')
        })
      }
    })
    commit('setChartSampleData', data)
  },
  defaultTableSampleData({ commit }) {
    const data = {
      head: ['Tax year', 'Date of payment', 'Amount paid', null, null],
      body: [
        [new Date().getFullYear() - 1, `08-26-${new Date().getFullYear() - 1}`, '$100', null, null],
        [new Date().getFullYear() - 1, `05-02-${new Date().getFullYear() - 1}`, '$200', null, null],
        [new Date().getFullYear() - 1, `02-15-${new Date().getFullYear() - 1}`, '$3500', null, null],
        [new Date().getFullYear() - 2, `03-23-${new Date().getFullYear() - 2}`, '$1200', null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ],
    }
    commit('setTableSampleData', data)
  },
  getWidgets({ commit }) {
    return HTTP.get(tenantSpecificURL(ServiceUrls.getWidgets, true))
      .then((res) => {
        commit('setWidgets', res.data)
        return res
      })
      .catch((error) => {
        commit('setWidgets', [])
        return Promise.reject(error)
      })
  },
  reorderWidgets({ commit }, data) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.updateReorderWidgets), data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getWidgetDetails({ dispatch, commit, state }, { widgetID, culture, multilingualSupport }) {
    const url = multilingualSupport ? `${ServiceUrls.getWidgetDetails}/${widgetID}/${culture}` : `${ServiceUrls.getWidgetDetails}/${widgetID}/en-us`
    return HTTP.get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  addWidgetDetails({ dispatch, commit, state }, widgetDetails) {
    return HTTP.post(`${ServiceUrls.addWidgetDetails}`, widgetDetails)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  updateWidgetDetails({ dispatch, commit, state }, widgetDetails) {
    return HTTP.post(`${ServiceUrls.updateWidgetDetails}`, widgetDetails)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  activateOrDeactivateWidget({ commit }, postObj) {
    return HTTP.post(`${ServiceUrls.activateOrDeactivateWidget}`, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  deleteWidget({ dispatch, commit, state }, postObj) {
    return HTTP.post(`${ServiceUrls.deleteWidget}`, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getCalendarTypes({ commit, rootState }, tenantId) {
    tenantId = tenantId || rootState.common.selectedTenantIdByRole
    const url = tenantId ? `${ServiceUrls.getCalendarTypes}/${tenantId}` : ServiceUrls.getCalendarTypes
    return HTTP.get(url)
      .then((res) => {
        commit('setCalendarTypeOptions', res.data)
        return res
      })
      .catch((error) => {
        commit('setCalendarTypeOptions', [])
        return Promise.reject(error)
      })
  },
  getCalendarCategories({ rootState }, postObj) {
    postObj.tenantId = postObj.tenantId ? postObj.tenantId : rootState.common.selectedTenantIdByRole
    const url = postObj.tenantId ? `${ServiceUrls.getCalendarCategories}/${postObj.tenantId}` : `${ServiceUrls.getCalendarCategories}`
    return HTTP.post(url, postObj.fieldSetIds)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getApplicableWidgets({ commit, rootState }, tenantID) {
    tenantID = tenantID || rootState.common.selectedTenantIdByRole
    const url = tenantID ? `${ServiceUrls.getApplicableWidgets}/${tenantID}` : ServiceUrls.getApplicableWidgets
    return HTTP.get(url)
      .then((res) => {
        commit('setApplicableWidgets', res.data.applicableWidgets)
        commit('setSignUpPageDisplayedWidgetCount', res.data.signUpPageDisplayedWidgetCount)
        return res
      })
      .catch((error) => {
        commit('setApplicableWidgets', [])
        commit('setSignUpPageDisplayedWidgetCount', null)
        return Promise.reject(error)
      })
  },

  getNewsCategories({ rootState }, tenantID) {
    tenantID = tenantID || rootState.common.selectedTenantIdByRole
    const url = tenantID ? `${ServiceUrls.getNewsCategories}/${tenantID}` : ServiceUrls.getNewsCategories
    return HTTP.get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getBlobIconList({ commit }, postObj) {
    const url = postObj.searchText ? `${ServiceUrls.getBlobIconList}/${postObj.page}/${postObj.searchText}` : `${ServiceUrls.getBlobIconList}/${postObj.page}`
    return HTTP.get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getStreetNames({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.getStreetNames), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getStreetNumbers({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.getStreetNumbers), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getUnitNumbers({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.getUnitNumbers), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  executeTestAPI({ commit }, postObj) {
    const url = postObj.widgetCategoryID === 4 ? ServiceUrls.executeLocationTestAPI : ServiceUrls.executeIntegrationTestAPI
    return HTTP.post(tenantSpecificURL(url), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getLinkedAccountUsers({ commit }, postObj) {
    return HTTP.post(tenantSpecificURL(ServiceUrls.getLinkedAccountUsers), postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getLinkedAccountUserAddresses({ commit }, postObj) {
    return HTTP.post(ServiceUrls.getLinkedAccountUserAddresses, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getMyWastPlatform({ commit, rootState }, tenantID) {
    tenantID = tenantID || rootState.common.selectedTenantIdByRole
    const url = tenantID ? `${ServiceUrls.getMyWastPlatform}/${tenantID}` : ServiceUrls.getMyWastPlatform
    return HTTP.get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}
// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setWidgets(state, payload) {
    state.widgets = payload
  },
  setCalendarTypeOptions(state, payload) {
    state.calendarTypeOptions = payload
  },
  setApplicableWidgets(state, payload) {
    state.applicableWidgets = payload
  },
  setSignUpPageDisplayedWidgetCount(state, payload) {
    state.signUpPageDisplayedWidgetCount = payload
  },
  setChartSampleData(state, payload) {
    state.chartSampleData = payload
  },
  setTableSampleData(state, payload) {
    state.tableSampleData = payload
  },
  setBangTheTableIsValid(state, payload) {
    state.bangTheTableIsValid = payload
  },
  setPerfectMindConfigIsValid(state, payload) {
    state.perfectMindConfigIsValid = payload
  },
  setContentWidgetEmailTemplate(state, payload) {
    state.contentWidgetEmailTemplate = payload
  },
  setWidgetDescriptions(state, payload) {
    state.widgetDescriptions = payload
  },
  setAddWidgetFormValidStatus(state, payload) {
    state.addWidgetFormValidStatus = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
