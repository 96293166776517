export const STATIC_LANGUAGE = {
  "Btn_Update": "Update",
  "Btn_Delete": "Delete",
  "Btn_Add": "Add",
  "Btn_Cancel": "Cancel",
  "Btn_Save": "Save",
  "Btn_Close": "Close",
  "Btn_Yes": "Yes",
  "Btn_No": "No",
  "Lnk_Account_Back_to_dashboard": "Back to dashboard",
  "Lnk_Header_Contact_us": "Contact us",
  "Txt_Header_Welcome": "Welcome",
  "Btn_Banner_Switch_property": "Switch property",
  "Btn_Dashboard_Edit_home_screen": "Edit home screen",
  "Lnk_Profile_Menu_Add_address": "Add address",
  "Lnk_Profile_Menu_Dark_mode": "Dark mode",
  "Lnk_Profile_Menu_Account": "Account",
  "Lnk_Profile_Menu_Help": "Help",
  "Lnk_Profile_Menu_Sign_out": "Sign out",
  "Btn_Widget_View_more": "View more",
  "Btn_Widget_Settings": "Settings",
  "Hdr_Account_Your_info": "Your information",
  "Hdr_Account_Property": "Property",
  "Hdr_Account_Widget_settings_notifications": "Widget settings & notifications",
  "Btn_Your_Info_Edit_profile": "Edit profile",
  "Btn_Your_Info_Change_password": "Change password",
  "Mdl_Txt_Property_My_properties": "My properties",
  "Mdl_Btn_Property_Add_property": "Add property",
  "Mdl_Hdr_Property_Add_property": "Add property",
  "Lnk_My_Property_Edit": "Edit",
  "Lnk_My_Property_Delete": "Delete",
  "Btn_Add_Property_Add": "Add",
  "Btn_Add_Property_Cancel": "Cancel",
  "Mdl_PlHdr_Add_Edit_Property_Address_name": "Property name",
  "Mdl_PlHdr_Add_Edit_Property_Address_name_Desc": "E.g., 'My Home', 'Mom's House'. This is not the street address.",
  "Mdl_PlHdr_Add_Edit_Property_Street_name": "Street name",
  "Mdl_PlHdr_Add_Edit_Property_Street_number": "Street number",
  "Mdl_PlHdr_Add_Edit_Property_unit_number": "Apt/unit number",
  "Txt_Please_enter": "Please enter a valid",
  "Btn_Add_Property_Update": "Update",
  "Txt_address": "address",
  "Mdl_Hdr_Please_confirm": "Please confirm",
  "Mdl_Msg_Delete_Property_Confirmation": "Are you sure you want to delete this property?",
  "Btn_Delete_Property_Yes": "Yes",
  "Btn_Delete_Property_No": "No",
  "Btn_Widget_Settings_My_widgets": "My widgets",
  "Btn_Widget_Settings_All_widgets": "All widgets",
  "Hdr_Widget_Settings_All_notification_settings": "All notification settings",
  "Txt_Widget_Settings_All_notification_settings": "You can change notification settings for ALL widgets by selecting an option from the dropdown list below. This will affect all widgets. Individual widget settings can be updated by selecting the preferred widget from the list on the left. Would you like notifications?",
  "Txt_Widget_Settings_Notification_type": "Notification type",
  "Slt_Widget_Settings_None": "None",
  "Slt_Widget_Settings_App_only": "App Only",
  "Slt_Widget_Settings_App_EmailImmediate": "App + Email (Immediate)",
  "Slt_Widget_Settings_App_EmailSummary": "App + Email (Daily Summary)",
  "Tst_Msg_Title_Success": "Success",
  "Tst_Msg_Title_Info": "Info",
  "Tst_Msg_Title_Warning": "Warning",
  "Tst_Msg_Title_Error": "Error",
  "Tst_Msg_Failed": "Something went wrong, please try again.",
  "Tst_Msg_Dashboard_Widgets_Error": "Failed to load widgets.",
  "Tst_Msg_Calendar_Events_Empty": "There are no upcoming events.",
  "Tst_Msg_News_Empty": "No News available.",
  "Tst_Msg_Location_Widget_Empty": "There are no details available.",
  "Tst_Msg_Engagement_Widget_Empty": "There are no Engagement opportunities available.",
  "Tst_Msg_Forgot_Password_Email_Sent": "Link to reset your password has been sent to your email id. Please check your mailbox.",
  "Tst_Msg_Password_Changed": "Your password has been changed successfully.",
  "Tst_Msg_Verify_Email": "Email verified successfully.",
  "Tst_Msg_Failed_Set_Default_Address": "Failed to set default address.",
  "Tst_Msg_Profile_Updated": "Your information details updated successfully.",
  "Tst_Msg_Widget_Settings_Updated": "settings updated successfully.",
  "Tst_Msg_Address_Updated": "Property details updated successfully.",
  "Tst_Msg_Widget_Reorder_Update_Success": "Widget order successfully updated.",
  "Tst_Msg_Link_Account_Success": "Account linked successfully.",
  "Tst_Msg_Unlink_Account_Success": "Account unlinked successfully.",
  "Tst_Msg_External_Link_Account_Error": "Failed to navigate external link account URL.",
  "Tst_Msg_Send_Verify_Mail": "Verification email sent. Please check your inbox.",
  "Tst_Msg_Address_Error": "We are unable to retrieve information for your address. Please try again later.",
  "Tst_Msg_Generic_Error": "We are unable to retrieve the information for this widget.",
  "Tst_Msg_Link_Account_Error": "Unable to link account.",
  "Tst_Msg_Unlink_Account_Error": "Unable to unlink account.",
  "Tst_Msg_Popup_Blocked_Message": "A pop-up blocker may be preventing the application from opening the page. If you have a pop-up blocker, try disabling it to open the window.",
  "Notify_Lbl_minutes_ago": "minutes ago",
  "Notify_Lbl_seconds_ago": "seconds ago",
  "Notify_Lbl_hours_ago": "Hours ago",
  "Notify_Lbl_days_ago": "days ago",
  "Notify_Lnk_notification": "notification",
  "Notify_Lnk_notifications": "notifications",
  "Notify_Lnk_Mark_all_as_read": "Mark all as read",
  "Notify_Lnk_View_all": "View all",
  "Notify_Lnk_View_less": "view less",
  "Notify_Lnk_Collapse_notifications": "Collapse notifications",
  "Notify_Lnk_Collapse_notification": "Collapse notification",
  "Lnk_Account_Back_to_account": "Back to account",
  "Lnk_Account_Back_to_widget_settings": "Back to widget settings & notifications",
  "Txt_Account_Hdr_Account": "Account",
  "Btn_Banner_Add_property": "Add property",
  "Txt_Dashboard_No_widgets": "No widgets available.",
  "Mdl_Hdr_Property_Edit_property": "Edit property",
  "Txt_Required": "required",
  "Mdl_PlHdr_Add_Edit_Property_No_street_names": "No street names found.",
  "Mdl_PlHdr_Add_Edit_Property_Start_typing_street_name": "Start typing your street name, and select it from the dropdown.",
  "Txt_Widget_Settings_All_notification": "All notification",
  "Txt_Widget_Settings_Like_Notification": "Would you like notifications?",
  "Txt_Widget_Settings_Notification_turned_off": "Notifications have been turned off for this widget.",
  "Txt_Widget_Settings_No_settings": "Widget settings not available.",
  "Btn_Widget_Settings_Unlink_account": "Unlink account",
  "Btn_Saving": "Saving…",
  "CB_Widget_Settings_Select_all": "Select all",
  "Txt_Widget_Settings_Show_widget": "Show widget on homescreen?",
  "Txt_Widget_Settings_Widget_hidden": "This widget cannot be hidden.",
  "Txt_Widget_Settings_Select_Locations": "Select Locations",
  "Mdl_Msg_Unlink_account_Confirmation": "Are you sure you want to unlink your account?",
  "Btn_Widget_Reorder_Add_widget": "Add widget",
  "Btn_Widget_Reorder_Save_home_screen": "Save home screen",
  "Mdl_Txt_Widget_Reorder_Added": "Added",
  "Mdl_Hdr_Widget_Reorder_Confirmation": "Cancel without saving",
  "Mdl_Msg_Widget_Reorder_Confirmation": "If you cancel without saving, any changes you have made will not save. Are you sure you want to cancel and go back to the home screen?",
  "Mdl_Btn_Widget_Reorder_Confirmation_Yes": "Yes, cancel",
  "Mdl_Btn_Widget_Reorder_Confirmation_No": "No, back to edit",
  "Txt_Widget_Reorder_Press_spacebar_instruction": "Press spacebar to grab and set the widget. Use arrow keys to move the widget.",
  "Txt_Widget_Reorder_Move_widget_instruction": "Move this widget to a new position.",
  "Btn_Dashboard_Collapse_all_widgets": "Collapse all widgets",
  "Btn_Dashboard_Expand_all_widgets": "Expand all widgets",
  "Txt_Signup_Address_Created_account": "You’ve created your account.",
  "Txt_Signup_Address_Experience_better": "Want to make your experience even better?",
  "Btn_Next": "Next",
  "Btn_Maybe_later": "Maybe later",
  "Txt_Signup_Address_Respect_privacy": "We respect your privacy. Read our",
  "Lnk_Signup_Address_Privacy_policy": "privacy policy.",
  "Txt_Hdr_Signup_Address_Privacy_policy": "Privacy policy",
  "Txt_Signup_Address_Whats_near_you": "Add your address to see what’s near you.",
  "Txt_Signup_Address_News": "News",
  "Txt_Signup_Address_Events": "Events",
  "Txt_Error_Page_Oops": "Oops!",
  "Txt_Error_Page_Something_wrong": "Something went wrong.",
  "Btn_Error_Page_Click_to_retry": "Click to retry",
  "Txt_Please_add": "Please add",
  "Txt_Your_address": "your address",
  "Txt_To_see_more_info": "to see more information on this widget.",
  "Txt_Contact_us": "contact us",
  "Txt_Problem_persists": "If the problem persists, please",
  "Txt_To_resolve_issue": "to resolve this issue.",
  "Txt_Language_Changed_Error": "Unable to change the language",
  "Hdr_Account_Preferences": "Preferences",
  "Txt_Account_Preferences_Language": "Language",
  "Txt_Account_Preferences_Language_Desc": "You can change your preferred language for the dashboard by selecting an option from the dropdown list below.",
  "Txt_Account_Preferences_Update_Error": "Failed to update language preference.",
  "Txt_Account_Preferences_Slt_Language": "Default language",
  "Mdl_Txt_Saved_Settings_Your_settings_saved": "Your settings have been saved.",
  "Mdl_Txt_Saved_Settings_Would_you_like": "Would you like to go back to the",
  "Mdl_Txt_Saved_Settings_Dashboard": "dashboard?",
  "Txt_Account_Preferences_Update_Success": "Language preference changed successfully.",
  "Mdl_Txt_Saved_Settings_Go_back_to": "Go back to",
  "Mdl_Txt_Saved_Settings_Stay_in_settings": "Stay in settings",
  "Lnk_Integration_Widget_Link_account_now": "Link account now",
  "Btn_Integration_Widget_Link_account": "Link account",
  "Btn_Integration_Widget_Rest_form": "Reset form",
  "Txt_Integration_Widget_Link_account_not_available": "Link account not available.",
  "Txt_FormBuilder_Enter_a_keyword": "Enter a keyword or a short description",
  "Txt_FormBuilder_Select_an_item": "Select an item from the list below or use the search bar above.",
  "Txt_Engagement_widget_Search_projects": "Search projects",
  "Txt_Engagement_widget_Stage": "Stage",
  "Txt_Engagement_widget_Of": "of",
  "Lnk_Location_widget_Map": "Map",
  "Txt_Mywaste_widget_Next_collection": "Next collection",
  "Lnk_Recreation_widget_Back": "Back",
  "Txt_Recreation_widget_No_results_found": "No results found",
  "Btn_Recreation_widget_Choose": "Choose",
  "Btn_Recreation_widget_Closed": "Closed",
  "Btn_Recreation_widget_Book_now": "Book now",
  "Lnk_Recreation_widget_Show": "Show",
  "Lnk_Recreation_widget_Hide": "Hide",
  "Txt_Recreation_widget_Select_location": "Select location",
  "Txt_Recreation_widget_Start_date": "Start date",
  "Txt_Recreation_widget_End_date": "End date",
  "Lnk_Recreation_widget_Hide_adv_filters": "Hide advanced filters",
  "Lnk_Recreation_widget_Adv_filters": "Advanced filters",
  "Btn_Recreation_widget_Apply_filters": "Apply filters",
  "Btn_Recreation_widget_Reset_filters": "Reset filters",
  "Txt_Recreation_widget_Start_time": "Start time",
  "Txt_Recreation_widget_End_time": "End time",
  "Txt_Delete_Account": "Delete account",
  "Txt_Delete_Account_Confirmation": "Are you sure you want to delete your account?",
  "Txt_Delete_Account_Confirmation_Description": "Deleting the account will permanently erase all your information and you will not be able to retrieve the account.",
  "Btn_Yes_Delete": "Yes, delete",
  "Btn_No_Back_To_Account": "No, back to account",
  "Txt_Msg_Account_Failed": "Failed to delete account"
}
