export const gtmScript = (headScript, bodyScript) => {
  if (headScript && bodyScript) {
    // Setting script tag at head section
    const scriptDiv = document.createElement('div')
    scriptDiv.innerHTML = headScript
    const script = document.createElement('script')
    script.text = scriptDiv.firstElementChild.textContent
    document.head.appendChild(script)
    // Setting script tag at body section
    const div = document.createElement('div')
    div.innerHTML = bodyScript
    const noScriptdiv = document.createElement('div')
    noScriptdiv.innerHTML = div.firstElementChild.textContent
    const noscript = document.createElement('noscript')
    noscript.appendChild(noScriptdiv.firstChild)
    document.body.prepend(noscript)
  }
}
