const getDefaultState = () => {
  return {
    contactUsUrl: null,
    buttonColor: null,
    dashboardSettingsNavigation: false,
    goToDashboardWidgetId: -1,
    showWidgetExpanedSecondModal: {
      show: false,
      widgetExpandedClick: false
    },
    formDetails: {
      loading: false,
      showFormDetails: false,
      data: null,
      error: null
    },
    recreationWidgetFilterForm: null,
    recreationWidgetLocations: [],
    recreationWidgetCategories: [],
    recreationWidgetSelectedProgram: '',
    recreationWidgetClassesFilterForm: null,
    recreationWidgetFilterFormError: { hasError: false, showSearchResult: true }
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {

}
// actions
const actions = {
}
// mutations
const mutations = {
  resetState(state) {
    const tempContactUsUrl = state.contactUsUrl
    const tempButtonColor = state.buttonColor
    Object.assign(state, getDefaultState())
    state.contactUsUrl = tempContactUsUrl
    state.buttonColor = tempButtonColor
  },
  setContactUsUrl(state, payload) {
    state.contactUsUrl = payload
  },
  setButtonColor(state, payload) {
    state.buttonColor = payload
  },
  setDashboardSettingsNavigation(state, payload) {
    state.dashboardSettingsNavigation = payload
  },
  setGoToDashboardWidgetId(state, payload) {
    state.goToDashboardWidgetId = payload
  },
  setShowWidgetExpanedSecondModal(state, payload) {
    state.showWidgetExpanedSecondModal = payload
  },
  setFormDetails(state, payload) {
    state.formDetails = payload
  },
  setRecreationWidgetFilterForm(state, payload) {
    state.recreationWidgetFilterForm = payload
  },
  setRecreationWidgetLocations(state, payload) {
    state.recreationWidgetLocations = payload
  },
  setRecreationWidgetCategories(state, payload) {
    state.recreationWidgetCategories = payload
  },
  setRecreationWidgetFilterFormError(state, payload) {
    state.recreationWidgetFilterFormError = payload
  },
  setRecreationWidgetSelectedProgram(state, payload) {
    state.recreationWidgetSelectedProgram = payload
  },
  setRecreationWidgetClassesFilterForm(state, payload) {
    state.recreationWidgetClassesFilterForm = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
