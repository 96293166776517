import Vue from 'vue'
Vue.directive('numberOnly', {
  bind: function (el) {
    el.handler = function () {
      el.value = el.value.replace(/\D+/, '')
    }
    el.addEventListener('input', el.handler)
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler)
  }
})
Vue.directive('amount', {
  bind: function (el) {
    el.handler = function () {
      el.value = el.value.match(/^\d+\.?\d{0,2}/)
    }
    el.addEventListener('input', el.handler)
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler)
  }
})
Vue.directive('srOnlyNewWindow', {
  bind: function (el) {
    el.innerHTML += '<span class="sr-only"> - opens in new window</span>'
  }
})
