import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import widgets from './modules/widgets'
import cities from './modules/cities'
import settings from './modules/settings'
import languages from './modules/languages'
import shared from '@portalStoreShared'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    widgets,
    cities,
    settings,
    languages,
    shared,
  }
})
