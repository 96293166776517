import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import FloatingVue from 'floating-vue'
import Vidle from 'v-idle'
import vSelect from 'vue-select'
import VCalendar from 'v-calendar'
import './utilities/filters'
import './utilities/customDirectives'
import '@portalCustomDirectives'
import { useTranslateLanguage } from '@portalTranslateLanguage'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'floating-vue/dist/style.css'
import './styles.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(Vidle)
Vue.use(FloatingVue)
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
Vue.component('v-select', vSelect)

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'govstack-ribbon'
]

const { translateLanguage } = useTranslateLanguage()
Vue.prototype.translateLanguage = (key) => {
  return translateLanguage.value[key] || key
}

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
