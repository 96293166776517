import { ref } from 'vue'
import { STATIC_LANGUAGE } from '../utilities/static-language'

const translateLanguage = ref(STATIC_LANGUAGE)

export function useTranslateLanguage() {
  const updateLanguage = (language) => {
    translateLanguage.value = { ...translateLanguage.value, ...language }
  }

  return { translateLanguage, updateLanguage }
}
