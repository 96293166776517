<template>
  <div></div>
</template>
<script>
import { msalInstance } from '@/msal/authConfig'
export default {
  name: 'Logout',
  created() {
    msalInstance.logoutRedirect({
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        console.log('Logout!')
        return false
      },
    })
  },
}
</script>
<style lang="scss" scoped></style>
