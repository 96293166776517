import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import SettingsCategories from '../components/settings/SettingsCategories.vue'
import Logout from '../components/logout/Logout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: 'unauthorized',
        name: 'Unauthorized',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../components/unauthorized/Unauthorized.vue'),
      },
      {
        path: 'languages',
        name: 'Languages',
        redirect: 'languages/languages-categories',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'languages/languages-categories',
        name: 'Languages categories',
        component: () => import('../components/languages/LanguageCategories.vue'),
        redirect: 'languages/languages-categories/languages',
        children: [
          {
            path: 'languages',
            name: 'Languages-list',
            component: () => import('../components/languages/Languages.vue'),
            meta: {
              requiresAuth: true,
              isMultilingualEnabled: true,
            },
          },
          {
            path: 'add-language',
            name: 'Add-language',
            meta: {
              requiresAuth: true,
              isMultilingualEnabled: true,
            },
            component: () => import('../components/languages/add-edit-language/AddEditLanguage.vue'),
          },
          {
            path: 'edit-language/:languageId',
            name: 'Edit-language',
            meta: {
              requiresAuth: true,
              isMultilingualEnabled: true,
            },
            component: () => import('../components/languages/add-edit-language/AddEditLanguage.vue'),
          },
        ],
      },
      {
        path: 'widgets',
        name: 'Widgets',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../components/widgets/Widgets.vue'),
      },
      {
        path: 'widgets/add-widget/:widgetCategoryId',
        name: 'Add-widget',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../components/widgets/add-edit-widget/AddEditWidget.vue'),
      },
      {
        path: 'widgets/edit-widget/:widgetID/:widgetCategoryId',
        name: 'Edit-widget',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../components/widgets/add-edit-widget/AddEditWidget.vue'),
      },
      {
        path: 'widgets/add-widgets',
        name: 'Add-widgets',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../components/widgets/add-edit-widget/AddWidget.vue'),
      },
      {
        path: 'cities',
        name: 'Cities',
        meta: {
          requiresAuth: true,
          requiresRole: true,
        },
        component: () => import('../components/cities/Cities.vue'),
      },
      {
        path: 'cities/add-city',
        name: 'Add-city',
        component: () => import('../components/cities/AddEditCity.vue'),
        redirect: 'cities/add-city/general',
        children: [
          {
            path: 'general',
            name: 'Add-general-city',
            meta: {
              requiresAuth: true,
              requiresRole: true,
            },
            component: () => import('../components/cities/GeneralCity.vue'),
          },
        ],
      },
      {
        path: 'cities/edit-city/:tenantID',
        name: 'Edit-city',
        component: () => import('../components/cities/AddEditCity.vue'),
        redirect: 'cities/edit-city/:tenantID/general',
        children: [
          {
            path: 'general',
            name: 'Edit-general-city',
            meta: {
              requiresAuth: true,
              requiresRole: true,
            },
            component: () => import('../components/cities/GeneralCity.vue'),
          },
          {
            path: 'widget-setup',
            name: 'City-widget-setup',
            meta: {
              requiresAuth: true,
              requiresRole: true,
            },
            component: () => import('../components/cities/WidgetSetup.vue'),
          },
          {
            path: 'address-setup',
            name: 'City-address-setup',
            meta: {
              requiresAuth: true,
              requiresRole: true,
            },
            component: () => import('../components/cities/AddressSetup.vue'),
          },
          {
            path: 'feature-setup',
            name: 'City-feature-setup',
            meta: {
              requiresAuth: true,
              requiresRole: true,
            },
            component: () => import('../components/cities/FeatureSetup.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: 'Settings',
        redirect: 'settings/settings-categories/general',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/settings-categories',
        name: 'Settings categories',
        component: SettingsCategories,
        redirect: 'settings/settings-categories/general',
        children: [
          {
            path: 'email',
            name: 'Email-settings',
            component: () => import('../components/settings/EmailSettings.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'address',
            name: 'Address-settings',
            component: () => import('../components/settings/address/Address.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'general',
            name: 'General-settings',
            component: () => import('../components/settings/Settings.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'link-accounts',
            name: 'Settings-link-accounts',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/LinkAccounts.vue'),
          },
          {
            path: 'add-link-account',
            name: 'Settings-add-link-account',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/link-accounts/add-link-account/AddEditInternalLinkAccount.vue'),
          },
          {
            path: 'edit-link-account/:id',
            name: 'Settings-edit-link-account',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/link-accounts/add-link-account/AddEditInternalLinkAccount.vue'),
          },
          {
            path: 'add-external-link-account',
            name: 'Settings-add-external-link-account',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/AddEditExternalLinkAccount.vue'),
          },
          {
            path: 'edit-external-link-account/:id',
            name: 'Settings-edit-external-link-account',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/AddEditExternalLinkAccount.vue'),
          },
          {
            path: 'security',
            name: 'Settings-security',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/security/Security.vue'),
          },
          {
            path: 'add-security-method',
            name: 'Settings-add-security-method',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/security/AddEditSecurityMethod.vue'),
          },
          {
            path: 'edit-security-method/:id',
            name: 'Settings-edit-security-method',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../components/settings/security/AddEditSecurityMethod.vue'),
          },
          {
            path: 'announcement-banner',
            name: 'Settings-announcement-banner',
            meta: {
              requiresAuth: true,
              isAnnouncementEnabled: true,
            },
            component: () => import('../components/settings/announcement-banner/AnnouncementBanner.vue'),
          },
        ],
      },
      {
        path: 'users',
        name: 'Users',
        meta: {
          requiresAuth: true,
          requiresUserAuth: true,
        },
        component: () => import('../components/users/Users.vue')
      },
      {
        path: 'users/userdetails/:userObjectID',
        name: 'User-details',
        meta: {
          requiresAuth: true,
          requiresUserAuth: true,
        },
        component: () => import('../components/users/UserDetails.vue')
      },
    ],
  },
  { path: '/logout', name: 'Logout', component: Logout },
  { path: '*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

function guardCheck(to, next) {
  const verifyAuthentication = ['/']
  const accountId = store.state.common.accountId
  const userRole = store.state.common.userRole
  if (to.meta.requiresAuth) {
    if (to.meta.requiresRole) {
      return userRole === 'SysAdmin'
        ? to.name === 'Cities' ||
          to.name === 'Add-general-city' ||
          to.name === 'Edit-general-city' ||
          to.name === 'City-widget-setup' ||
          to.name === 'City-address-setup' ||
          to.name === 'City-feature-setup'
          ? next()
          : next({ name: 'Cities' })
        : to.path === '/widgets'
          ? next()
          : next({ name: 'Widgets' })
    } else {
      if (userRole === 'Unauthorized') {
        return to.name === 'Unauthorized' ? next() : next({ name: 'Unauthorized' })
      } else {
        return to.name === 'Unauthorized' ? next({ path: '/' }) : next()
      }
    }
  } else {
    if (accountId && verifyAuthentication.includes(to.path)) {
      return userRole === 'SysAdmin' ? next({ name: 'Cities' }) : next({ name: 'Widgets' })
    } else {
      next()
    }
  }
}

function getAccountId(to, next) {
  store
    .dispatch('common/selectAccount')
    .then((res) => {
      guardCheck(to, next)
    })
    .catch(() => {
      guardCheck(to, next)
    })
}

router.beforeEach((to, from, next) => {
  const accountId = store.state.common.accountId
  if (accountId) {
    guardCheck(to, next)
  } else {
    getAccountId(to, next)
  }
})

export default router
